import util from '../util'

export default {
    info, query, user, last, wxconfig, geten, detail, send
}

async function info(key) {
    let cache = util.get(key)
    if (cache) {
        return cache
    }
    let res = await util.http('apply/info', { key })
    if (res.status) {
        util.set(key, res)
    }
    return res
}

async function query(name) {
    if (!name) {
        return null
    }
    let res = await util.http('Enterprise/Query', { name: name, limit: 8 })
    if (res.status) {
        return res
    }
    return null
}

function geten(id) {
    return util.http(`enterprise/get`, { id })
}

async function user(code, key) {
    let user = util.get(code)
    if (user) {
        return user
    }
    let info = util.get(key)
    if (!info || !info.status) {
        return null
    }
    let res = await util.http('wx/userinfo', { code: code, appId: info.data.appId, componentAppId: info.data.componentAppId })
    if (res.status) {
        util.set(code, res)
    }
    return res;
}

function detail(key) {
    return util.http(`apply/detail/${key}`)
}

function last(openid) {
    return util.http(`apply/lastinfo`, { openid })
}

function wxconfig(appId, url) {
    url = url || document.location.href;
    return util.http(`wx/jsconfig`, { appId, url })
}

function send(key, email) {
    return util.http(`invoice/notify`, { key, email, onlyEmail: true })
}