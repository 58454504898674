import config from './config'
import axios from 'axios'

function tc(number) {
    // number = (number + '').replace(/[^0-9+-Ee.]/g, '')
    // const n = !isFinite(+number) ? 0 : +number
    // const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    // const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
    // const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    // let s = ''
    // const toFixedFix = function (n, prec) {
    //     const k = Math.pow(10, prec)
    //     return '' + Math.ceil(n * k) / k
    // }
    // s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
    // const re = /(-?\d+)(\d{3})/
    // while (re.test(s[0])) {
    //     s[0] = s[0].replace(re, '$1' + sep + '$2')
    // }
    // if ((s[1] || '').length < prec) {
    //     s[1] = s[1] || ''
    //     s[1] += new Array(prec - s[1].length + 1).join('0')
    // }
    // return `¥${s.join(dec)}`
    return number.toLocaleString('zh-CN', { style: 'currency', currency: 'CNY' })
}

function get(key) {
    let json = sessionStorage.getItem(key)
    if (json) {
        return JSON.parse(json)
    }
    return null
}

function set(key, val) {
    if (key && val) {
        sessionStorage.setItem(key, JSON.stringify(val))
    }
}

async function http(path, val) {
    let url = `${config.api}/${path}`
    let res = null
    if (val) {
        res = await axios.post(url, val)
    }
    else {
        res = await axios.get(url)
    }
    if (res.status === 200) {
        return res.data
    }
    return { status: false, msg: '网络连接异常', rid: 'net_work_error' }
}

function reult(res) {
    return `/msg/${res.status ? 'success' : res.requestId}/${encodeURIComponent(res.msg)}`
}

export default { tc, get, set, http, reult }